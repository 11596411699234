<template>
  <div class="coianter">
    <div class="flexrow tiecg">
      <div class="tilelft">
        <div style="margin-right: 10px">{{ form.proName }}</div>
        <div class="label_list" v-if="labelList.length > 0">
          <span class="tags">标签:</span>
          <div v-for="(item, index) in labelList" :key="index" class="btns" >
            <el-tag size="mini" type="info" effect="dark">{{ item.label }}</el-tag>
          </div>
        </div>
        <div>
          <span style="font-size: 14px; margin-right: 10px">状态:</span>
          <el-tag size="mini" v-if="form.status == 1">待提交</el-tag>
          <el-tag size="mini" v-else-if="form.status == 2">待受理</el-tag>
          <el-tag size="mini" v-else-if="form.status == 3">待修改中</el-tag>
          <el-tag size="mini" v-else-if="form.status == 4">待立项</el-tag>
          <el-tag size="mini" v-else-if="form.status == 5">立项不通过</el-tag>
          <el-tag size="mini" v-else-if="form.status == 6">待提交初稿</el-tag>
          <el-tag size="mini" v-else-if="form.status == 7">待初稿审查</el-tag>
          <el-tag size="mini" v-else-if="form.status == 8">待完善审查材料</el-tag>
          <el-tag size="mini" v-else-if="form.status == 9">待提交征求意见稿</el-tag>
          <el-tag size="mini" v-else-if="form.status == 10">待征求意见</el-tag>
          <el-tag size="mini" v-else-if="form.status == 11">待提交送审稿及材料</el-tag>
          <el-tag size="mini" v-else-if="form.status == 12">待送审</el-tag>
          <el-tag size="mini" v-else-if="form.status == 13">待提交报批稿</el-tag>
          <el-tag size="mini" v-else-if="form.status == 14">待报批</el-tag>
          <el-tag size="mini" v-else-if="form.status == 15">已报批</el-tag>
          <el-tag size="mini" v-else-if="form.status == 16">留档</el-tag>
          <el-tag size="mini" v-else-if="form.status == 17">暂停</el-tag>
          <el-tag size="mini" v-else-if="form.status == 18">终止</el-tag>
          <el-tag size="mini" v-else-if="form.status == 26">待提交材料</el-tag>
          <el-tag size="mini" v-else-if="form.status == 27">已提交</el-tag>
          <el-tag size="mini" v-else-if="form.status == 28">公平竞争审查未通过</el-tag>
          <el-tag size="mini" v-else-if="form.status == 29">公平竞争审查通过</el-tag>
          <!-- <el-tag v-if="nowType == 1">初审</el-tag>
          <el-tag v-if="nowType == 2">复审</el-tag>
          <el-tag v-else-if="nowType == 3">终审</el-tag>
          <el-tag v-else-if="nowType == 7">审查会</el-tag> -->
        </div>
      </div>
      <div class="tieright">
        <span class="tip_text">注意：该下载包是下载该项目已有的所有文件</span>
        <el-button type="primary" @click="downAllDoc">下载所有资料</el-button>
        <el-button type="primary" @click="goback">返回</el-button>
      </div>
    </div>
    <div class="idteail">
      <div style="padding-left: 10px">
        <el-tabs v-model="activeName" @tab-click="handleClicktab" type="border-card">
          <el-tab-pane label="立项信息" name="first">
            <lixiangmanger @changeNowType="changeNowType" />
          </el-tab-pane>
          <el-tab-pane label="初稿审查" name="second" :disabled="form.status < 6">
            <draftReview @changeNowType="changeNowType" />
          </el-tab-pane>
          <el-tab-pane label="征求意见稿" name="third" :disabled="form.status < 9">
            <solicitingOpinions @changeNowType="changeNowType" />
          </el-tab-pane>
          <el-tab-pane label="初稿审查会议纪要" name="fourth" :disabled="form.status < 11">
            <examination @changeNowType="changeNowType" />
          </el-tab-pane>
          <el-tab-pane label="报批" name="five" :disabled="form.status < 13">
            <keeponrecord @changeNowType="changeNowType" />
          </el-tab-pane>
          <el-tab-pane label="公平竞争审查" name="six" :disabled="form.status < 26">
            <competeJust @changeNowType="changeNowType" />
          </el-tab-pane>
          <el-tab-pane label="其他" name="seven">
            <otherFilesDoc @changeNowType="changeNowType" />
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div class="" style="float: right">
        <i class="el-icon-close" @click="$router.go(-1)"> </i>
      </div> -->
    </div>
  </div>
</template>
<script>
// projectTableInfo,
import { getBiaozhunDetail, addBiaozhun, standardFilesZip } from "@api/project";
import draftReview from "./shenhe/draftReviewqy.vue";
import lixiangmanger from "./shenhe/lixiangmanger.vue";
import solicitingOpinions from "./shenhe/solicitingOpinions.vue";
import examination from "./shenhe/examination.vue";
import keeponrecord from "./shenhe/keeponrecord.vue";
import competeJust from "./shenhe/competeJust.vue";
import otherFilesDoc from "./shenhe/otherFilesDoc.vue";
import moment from "moment";
export default {
  components: {
    draftReview,
    lixiangmanger,
    solicitingOpinions,
    examination,
    keeponrecord,
    competeJust,
    otherFilesDoc
  },
  data() {
    return {
      nowType: 1,
      htmlTitle: "页面导出PDF文件名",
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      form: {
        stars: 1,
        shencha: "",
      },
   
      countyOptions: [],
      value1: "",
      value2: "",
      value3: "",
      activeName: "first",
      tableData: [],
      tableData2: [
        {
          start_time: "",
          endtime: "",
          worktext: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext2: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext3: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext4: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext5: "",
        },
      ],
      textName: "",
      id: "",
      token: "",
      page: 1, //第几页
      size: 3, //一页多少条
      total: 0, //总条目数
      labelList:[]
    };
  },
  mounted() {
    this.getIdAndToken();
  },
  methods: {
    changeNowType(nowType) {
      this.nowType = nowType;
    },
    goback() {
      this.$router.go(-1);
    },
    momentBytime(date) {
      let y = moment(date).format("YYYY-MM-DD");
      return y;
    },
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.getInfo();
    },
    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          this.labelList = res.data.label;
          this.tableData = res.data.fu; //主要起草人员展开无分页
          // this.tableData = res.data.fu.slice(   主要起草人员分页
          //   (this.page - 1) * this.size,
          //   this.page * this.size
          // );
          // this.total=res.data.fu.length
          // console.log(this.form);
          this.tableData2[0].start_time = res.data.start_time1;
          this.tableData2[0].endtime = res.data.endtime1;
          this.tableData2[1].start_time = res.data.start_time2;
          this.tableData2[1].endtime = res.data.endtime2;
          this.tableData2[2].start_time = res.data.start_time3;
          this.tableData2[2].endtime = res.data.endtime3;
          this.tableData2[3].start_time = res.data.start_time4;
          this.tableData2[3].endtime = res.data.endtime4;
          this.tableData2[4].start_time = res.data.start_time5;
          this.tableData2[4].endtime = res.data.endtime5;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //切换导航
    handleClicktab(tab, event) {
      console.log(tab, event);
    },
    //图片上传成功
    photo_success(re, file, fileList) {
      // console.log(fileList);
    },
    fileUploadSuccess(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
        return item.response.data;
      });
      // console.log(this.fileList);
    },
 
    gotoStep() {
      // let url = '/formal/proDetailDownLoad?id='+id;
      this.$router.push({
        path: "../../formal/proDetailDownLoad?id=" + this.id,
      });
      // return url;
    },
    //通过
    saveProject() {
      this.form.status = 4;
      this.form.token = this.token;
      console.log(this.form);
      addBiaozhun(this.form).then((rec) => {
        console.log(rec);
      });
      this.$router.push({
        path: "../../formal/formalPend",
      });
    },
    //退回 修改状态
    changeStatus() {
      this.form.status = 3;
      this.form.token = this.token;
      console.log(this.form);
      addBiaozhun(this.form).then((rec) => {
        console.log(rec);
      });
      this.$router.push({
        path: "../../formal/formalPend",
      });
    },
    handleRemove(file) {
      // console.log(file, "2");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file, "1");
    },

    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      console.log("翻页，当前为第几页", val);
      this.page = val;
      this.getInfo();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      console.log("改变每页多少条，当前一页多少条数据", val);
      this.size = val;
      this.page = 1;
      this.getInfo();
    },
    //下载所有资料
    downAllDoc() {
      let sendparams = {
        id: this.id,
        token: this.token
      }
      standardFilesZip(sendparams).then((res) => {
        // console.log(res)
        let blob = new Blob([res], { type: 'application/zip' })
        let url = window.URL.createObjectURL(blob)
        const link = document.createElement('a') // 创建a标签
        link.href = url
        link.download = '地方标准审批.zip' // 重命名文件
        link.click()
        URL.revokeObjectURL(url) // 释放内存
      });
    }
  },
};
</script>
<style scoped>
/deep/.el-tabs__item {
  font-size: 20px;
  font-weight: 600;
  width: 14.5%;
  text-align: center;
}

/deep/.el-tabs__nav {
  background-color: transparent;
  width: 100%;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
  /* width: 100%; */
}

/deep/ .el-input {
  width: 250px;
}

/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}
</style>
<style lang="scss" scoped>
.coianter {
  width: 100%;
  height: 800px;

  .label_list {
    display: flex;

    .tags {
      font-size: 14px;
      padding-top: 3px;
      padding-right: 5px;
    }

    .btns {
      margin-right: 11px;
    }
  }
}

.tieright {
  .tip_text {
    color:#909399;
    font-size:14px;
    padding-right:11px;
  }
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;
}

.idteail {
  margin: 0 0 0px 0px;
  width: 100%;

  // height:60px;
  // background-color: #ffffff;
  // box-shadow: #cccbcb 0px -1px 10px;
  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.title {
  background: white;
  width: 100%;

  .titleicon {
    margin: 10px;
    width: 4px;
    height: 24px;
    background: #3082e2;
  }

  .titletext {
    font-size: 22px;
    font-family: Adobe Heiti Std;
    color: #3082e2;
    line-height: 65px;
  }
}

.comit {
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;

  .baocun {
    margin: 0 25px;
  }
}

.name_input {
  width: 100%;
}

.name_input>.el-form-item {
  width: 100%;
}

.name_input>.el-form-item .el-input {
  width: 100%;
}

.borderstyle {
  border: 1px solid;
}

.centerstyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexstyle {
  display: flex;
  flex-direction: row;
}

.tableTitle-tr {
  height: 60px;
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid #3082e2;
    background: #eaf2fc;
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tableTitle-tr2 {
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    background: #eaf2fc;
    text-align: center;
  }

  .shijianstyle {
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tiwen {
  height: 160px;
  box-shadow: 0px 6px 11px 2px rgba(72, 77, 84, 0.2);

  .tiwenText {
    width: 100%;
    height: 100%;
    background: rgba(93, 160, 236, 0.1);
    border: 0px;
    resize: none;
  }
}

.borderstyle {
  border: 1px solid;
}

table {
  border: 0;
  border-collapse: collapse;
}

.tableTitle-tr-pdf {
  height: 60px;
  font-size: 16px;
  border: 1px solid #000305;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    //background: #EAF2FC;
    text-align: center;
  }

  .tdcontent {
    border: 1px solid #000305;
    width: 500px;
  }

  .tdcontent2 {
    border: 1px solid #000305;
    width: 300px;
  }

  .bianzhitd {
    border: 1px solid #000305;
    width: 1415px;
  }
}

td {
  border: 1px solid #3082e2;
}

//这里改
.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
