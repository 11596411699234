<template>
  <div>
    <div class="flexrow">
      <div class="leftcg">
        <div class="lefttie">
          <div class="ltleft">审查资料</div>
        </div>
        <div class="leftfile">
          <div class="filelistcg" v-if="materImgList">
            <div
              class="flexrow cgfiles"
              v-for="(item, index) in materImgList"
              :key="index"
            >
              <div class="cgfleft">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="item.name"
                  placement="bottom"
                >
                  <div class="cgfleftto">{{ item.name }}</div>
                </el-tooltip>
              </div>
              <div
                class="cgfrig"
                v-if="item.url.endsWith('.doc') || item.url.endsWith('.docx')"
              >
                <a
                  :href="showFileSrc"
                  target="_blank"
                  class="ltright"
                  style="color: #509bfd; text-decoration: none"
                  >下载</a
                >
              </div>
              <div class="cgfrig" @click="viewFile(item.url)" v-else>查看</div>
            </div>
          </div>
        </div>
      </div>
      <div class="centercg">
        <div class="lefttie">
          <div class="ltleft">文件预览</div>
          <div class="ltright" v-if="showFileSrc">
            <a :href="showFileSrc" target="_blank" class="ltright">全屏预览</a>
          </div>
        </div>
        <div v-if="showFileSrc.length > 0" class="fileiframe">
          <div ref="docx_dom" class="iframe" v-if="showFileType == 1"></div>
          <iframe
            frameborder="0"
            class="iframe"
            v-else-if="showFileType == 2"
            :src="showFileSrc"
          ></iframe>
        </div>
      </div>
      <div class="rightcg">
        <div class="lefttie" style="margin-bottom: 10px; border-bottom: none">
          <div class="ltleft">审查意见</div>
        </div>
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :style="
              index == activities.length - 1
                ? 'color: #3082e2'
                : 'color: #999999'
            "
          >
            <div class="flex-row">
              <div class="checktitle" style="color: #999999">
                {{ activity.type | shenchaType }}
              </div>
              <div class="shehe">审核人：{{ activity.uname }}</div>
            </div>
            <div class="kuang">
              <div class="cr-card">
                <div class="checkcont">
                  {{ activity.content }}
                </div>
                <div class="chfiles">
                  <div
                    class="cgfleft"
                    v-for="(item, index) in activeFile(activity.type)"
                    :key="index"
                  >
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.name"
                      placement="bottom"
                    >
                      <div class="cgfleftto" @click="zhanshi(item)">
                        {{ item.name }}
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="linetie">
                <div class="lttime">
                  <!-- <i class="el-icon-time"></i> -->
                  {{ activity.created_at | formatDate }}
                </div>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
        <!-- <div class="flexrow tgbtn">
          <el-button type="success" :disabled="disabled" @click="tosus"
            >通过</el-button
          >
          <el-button type="danger" @click="tuihui" :disabled="disabled"
            >退回</el-button
          >
        </div> -->
      </div>
    </div>
    <el-dialog title="意见模板" :visible.sync="dialogVisible" width="50%">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入初审意见"
        v-model="mbcontent"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMuban">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getBiaozhunDetail,
  addExam,
  spRizhi,
  spDetail,
  addmuban,
  mubanlist,
  delmuban,
} from "../../../../../api/project";
import axios from "axios";
let docx = require("docx-preview");
export default {
  filters: {
    shenchaType(type) {
      if (type == 1 || type == 4) {
        return "初审";
      } else if (type == 2 || type == 5) {
        return "复审";
      } else if (type == 3 || type == 6) {
        return "终审";
      } else if (type == 7) {
        return "审查意见";
      }
    },
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      materImgList: [],
      reverse: false,
      form: {},
      activities: [{}],
      content: "",
      textarea: "",
      showFileSrc: "",
      showFileType: "",
      nowType: 1,
      disabled: false,
      dialogVisible: false,
      mbcontent: "",
      mblist: [],
      materImgList3: [],
    };
  },
  mounted() {
    this.getIdAndToken();
    this.mubanList();
    // this.checkdetail();
    // this.checkrz();
  },
  methods: {
    zhanshi(item) {
      console.log("2222");
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(item.url);
    },
    activeFile(type) {
      if (type == 1 || type == 4) {
        return this.materImgList3.filter((item) => item.type == 41);
      } else if (type == 2 || type == 5) {
        return this.materImgList3.filter((item) => item.type == 42);
      } else if (type == 3 || type == 6) {
        return this.materImgList3.filter((item) => item.type == 43);
      } else if (type == 7) {
        return this.materImgList3.filter((item) => item.type == 45);
      }
    },
    delmblist() {
      console.log("mblist", this.mblist[0].id);
      let mbid = this.mblist[0].id;
      let params = {
        token: this.$store.state.token,
        id: mbid,
      };
      delmuban(params).then((res) => {
        console.log("删除模板", res);
        this.$message.success("删除成功");
        this.dialogVisible = false;
        this.getIdAndToken();
        this.mubanList();
      });
    },
    changeContent(item) {
      this.content = item.content;
    },
    addMuban() {
      let params = {
        token: this.$store.state.token,
        content: this.mbcontent,
        type: "1",
      };
      addmuban(params).then((res) => {
        console.log("模板", res);
        this.$message.success("添加成功");
        this.dialogVisible = false;
        this.getIdAndToken();
      });
    },
    mubanList() {
      let params = {
        token: this.$store.state.token,
        page: 1,
        limit: 15,
      };
      mubanlist(params).then((res) => {
        console.log("模板列表", res);
        this.mblist = res.data;
      });
    },
    showNowType() {
      let lastType = 0;
      if (this.activities.length > 0) {
        lastType = this.activities[this.activities.length - 1].type;
      }
      if (lastType == 0 || lastType == 4 || lastType == 5 || lastType == 6) {
        this.nowType = 1;
      } else if (lastType == 1) {
        this.nowType = 2;
      } else if (lastType == 2) {
        this.nowType = 3;
      } else if (lastType == 3) {
        this.nowType = -1;
      }
      this.$emit("changeNowType", this.nowType == -1 ? 3 : this.nowType);
    },
    viewFile(src) {
      this.showFileSrc = src;
      if (this.showFileSrc.lastIndexOf(".") != -1) {
        if (
          this.showFileSrc
            .substring(this.showFileSrc.lastIndexOf("."))
            .indexOf("doc") != -1
        ) {
          this.showFileType = 1;
          this.$nextTick(function () {
            axios({
              method: "get",
              responseType: "blob", // 设置响应文件格式
              url: src,
            }).then(({ data }) => {
              docx.renderAsync(data, this.$refs.docx_dom); // 获取到biob文件流，进行渲染到页面预览
            });
          });
        } else {
          this.showFileType = 2;
        }
      } else {
        this.showFileType = "";
      }
    },
    checkdetail() {
      let params = {
        token: this.$store.state.token,
        id: this.$route.query.id,
      };
      spDetail(params).then((res) => {
        console.log("审批详情", res);
        if (this.type == "1") {
          this.$message.success("初审成功");
        }
      });
    },
    checkrz() {
      let params = {
        token: this.$store.state.token,
        id: this.$route.query.id,
      };
      spRizhi(params).then((res) => {
        console.log("审批日志", res);
        if (this.type == "1") {
          this.$message.success("初审成功");
        }
      });
    },
    tuihui() {
      let type = 0;
      if (this.nowType == 1) {
        type = 4;
      } else if (this.nowType == 2) {
        type = 5;
      } else if (this.nowType == 3) {
        type = 6;
      }
      let params = {
        token: this.$store.state.token,
        sid: this.$route.query.id,
        status: "13",
        content: this.content,
        type: type,
      };
      if (this.nowType == 3) {
        params.status = 16;
      }
      this.disabled = true;
      addExam(params)
        .then((res) => {
          console.log("退回审批", res);
          if (this.nowType == 7) {
            this.$message.success("标准终止，标准信息留档");
          } else {
            this.$message.success("退回到用户");
          }
        })
        .catch((err) => {
          this.disabled = true;
        });
    },
    tosus() {
      if (this.nowType == "1" || this.nowType == "2") {
        let params = {
          token: this.$store.state.token,
          sid: this.$route.query.id,
          status: "14",
          content: this.content,
          type: this.nowType,
        };
        this.disabled = true;
        addExam(params)
          .then((res) => {
            console.log("提交审批", res);
            if (this.nowType == "1") {
              this.$message.success("初审成功");
            }
            if (this.nowType == "2") {
              this.$message.success("复审成功");
            }
            if (this.nowType == "3") {
              this.$message.success("终审成功");
            }
          })
          .catch((err) => {
            this.disabled = false;
          });
      }
      if (this.nowType == "3") {
        let params = {
          token: this.$store.state.token,
          sid: this.$route.query.id,
          status: "14",
          content: this.content,
          type: this.nowType,
          after_status: "15",
        };
        this.disabled = true;
        addExam(params)
          .then((res) => {
            console.log("终审通过", res);
            this.$message.success("终审通过");
          })
          .catch((err) => {
            this.disabled = false;
          });
      }
    },
    goback() {
      this.$router.go(-1);
    },
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.getInfo();
    },
    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          console.log("detial", res);
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          this.tableData = res.data.fu; //主要起草人员展开无分页
          this.activities = res.data.examines.filter(
            (item) => item.status == 28 || item.status == 29
          );
          this.materImgList = res.data.files.filter(
            (item) =>
              item.type == 25 ||
              item.type == 26 ||
              item.type == 27 ||
              item.type == 28 ||
              item.type == 29 ||
              item.type == 30 ||
              item.type == 31 ||
              item.type == 32 ||
              item.type == 33
          );
          this.materImgList3 = res.data.files.filter(
            (item) =>
              item.type == 43 ||
              item.type == 41 ||
              item.type == 42 ||
              item.type == 45
          );
          if (this.materImgList && this.materImgList.length > 0) {
            this.viewFile(this.materImgList[0].url);
          }
          this.showNowType();
          console.log(this.form);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #ffffff;
}
/deep/.el-textarea__inner {
  border: none;
  min-height: 100px !important;
}
/deep/.el-textarea {
  height: 100px;
}
/deep/.el-timeline {
  padding-left: 10px;
  height: 75vh;
  overflow: hidden;
  overflow-y: scroll;
}
/deep/.el-timeline-item__content {
  padding-right: 10px;
}
.el-timeline::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped lang="scss">
.cgfleftto {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.cgfleftto:hover {
  color: #3082e2;
  cursor: pointer;
}
.chfiles {
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
  padding-top: 10px;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.shehe {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  width: 37%;
}
.flowrow {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
  .flowleft {
    font-size: 16px;
    font-weight: 400;
    color: #3082e2;
    margin-right: 10px;
  }
  .flowright {
    color: #3082e2;
    margin-top: 2px;
  }
}
.mbline {
  height: 35px;
  line-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .neirong {
    width: 80%;
    cursor: pointer;
  }
  .neirongto {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .delbtn {
    cursor: pointer;
  }
}
.checktitle {
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  padding-top: 3px;
}
.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
}
.tiecg {
  margin-bottom: 20px;
}
.lefttie {
  border-bottom: 1px solid #dbe9ef;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ltleft {
    border-left: 4px solid #3082e2;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #3082e2;
  }
  .ltright {
    font-size: 14px;
    color: #509bfd;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
}
.leftcg {
  width: 19%;
  background: #ffffff;
}
.centercg {
  width: 55%;
  background: #ffffff;
}
.rightcg {
  width: 24%;
  padding-right: 20px;
}
.linetie {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-top: 15px;
  .lttime {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
  .ltmanger {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding-right: 10px;
  }
}
.kuang {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.29);
  margin: 10px 0;
  padding: 10px 15px;
}
.cr-card {
  .checkcont {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    // border-bottom: 1px solid #dbe9ef;
  }
}
.tgbtn {
  padding: 30px 58px;
}
.leftfile {
  padding: 10px 20px;
  .filelistcg {
    .cgfiles {
      height: 54px;
      background: #f4f4f4;
      line-height: 54px;
      padding: 0 20px;
      margin-bottom: 10px;
      .cgfleft {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        width: 80%;

        .cgfleftto {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .cgfrig {
        font-size: 14px;
        font-weight: 400;
        color: #509bfd;
        cursor: pointer;
      }
      .cgfrig:hover {
        color: #84b5ec;
      }
    }
  }
}

.fileiframe {
  height: 74vh;
  padding: 10px 1%;
  // overflow: auto;

  .iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
