<template>
  <div>
    <div class="pitable">
      <div class="upload_btn">
        <upload-file ref="uploadimg" :fileType="fileType" @standard-data="getFileData" :isShow="isShow"></upload-file>
      </div>
      <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%"
        :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
        <el-table-column label="序号" type="index">
        </el-table-column>
        <el-table-column label="文件名称" prop="name" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="primary" @click="download(row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <el-dialog title="意见模板" :visible.sync="dialogVisible" width="50%">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入初审意见"
        v-model="mbcontent"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMuban">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
import {
  getBiaozhunDetail,
  addExam,
  spRizhi,
  spDetail,
  addmuban,
  mubanlist,
  delmuban,
  otherFiles
} from "../../../../../api/project";
import axios from "axios";
import uploadFile from "../../technicalReview/components/uploadFile.vue"
let docx = require("docx-preview");
export default {
  components: { uploadFile },
  filters: {
    shenchaType(type) {
      if (type == 1 || type == 4) {
        return "初审";
      } else if (type == 2 || type == 5) {
        return "复审";
      } else if (type == 3 || type == 6) {
        return "终审";
      } else if (type == 7) {
        return "审查意见";
      }
    },
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      materImgList: [],
      reverse: false,
      form: {},
      activities: [{}],
      content: "",
      textarea: "",
      showFileSrc: "",
      showFileType: "",
      nowType: 1,
      disabled: false,
      dialogVisible: false,
      mbcontent: "",
      mblist: [],
      materImgList3: [],
      rowId: "",
      tableData: [],
      fileType: 'fileWord',
      isShow: false
    };
  },
  mounted() {
    this.getIdAndToken();
    this.mubanList();
    // this.checkdetail();
    // this.checkrz();
  },
  methods: {
    zhanshi(item) {
      console.log("2222");
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(item.url);
    },
    //获取文件的数据
    getFileData(obj) {
      console.log(obj, '打印文件对象')
      this.submitStatus(obj.data.name, obj.data.url)
    },
    activeFile(type) {
      if (type == 1 || type == 4) {
        return this.materImgList3.filter((item) => item.type == 41);
      } else if (type == 2 || type == 5) {
        return this.materImgList3.filter((item) => item.type == 42);
      } else if (type == 3 || type == 6) {
        return this.materImgList3.filter((item) => item.type == 43);
      } else if (type == 7) {
        return this.materImgList3.filter((item) => item.type == 45);
      }
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    delmblist() {
      console.log("mblist", this.mblist[0].id);
      let mbid = this.mblist[0].id;
      let params = {
        token: this.$store.state.token,
        id: mbid,
      };
      delmuban(params).then((res) => {
        console.log("删除模板", res);
        this.$message.success("删除成功");
        this.dialogVisible = false;
        this.getIdAndToken();
        this.mubanList();
      });
    },
    changeContent(item) {
      this.content = item.content;
    },
    addMuban() {
      let params = {
        token: this.$store.state.token,
        content: this.mbcontent,
        type: "1",
      };
      addmuban(params).then((res) => {
        console.log("模板", res);
        this.$message.success("添加成功");
        this.dialogVisible = false;
        this.getIdAndToken();
      });
    },
    mubanList() {
      let params = {
        token: this.$store.state.token,
        page: 1,
        limit: 15,
      };
      mubanlist(params).then((res) => {
        console.log("模板列表", res);
        this.mblist = res.data;
      });
    },
    showNowType() {
      let lastType = 0;
      if (this.activities.length > 0) {
        lastType = this.activities[this.activities.length - 1].type;
      }
      if (lastType == 0 || lastType == 4 || lastType == 5 || lastType == 6) {
        this.nowType = 1;
      } else if (lastType == 1) {
        this.nowType = 2;
      } else if (lastType == 2) {
        this.nowType = 3;
      } else if (lastType == 3) {
        this.nowType = -1;
      }
      this.$emit("changeNowType", this.nowType == -1 ? 3 : this.nowType);
    },
    viewFile(src) {
      this.showFileSrc = src;
      if (this.showFileSrc.lastIndexOf(".") != -1) {
        if (
          this.showFileSrc
            .substring(this.showFileSrc.lastIndexOf("."))
            .indexOf("doc") != -1
        ) {
          this.showFileType = 1;
          this.$nextTick(function () {
            axios({
              method: "get",
              responseType: "blob", // 设置响应文件格式
              url: src,
            }).then(({ data }) => {
              docx.renderAsync(data, this.$refs.docx_dom); // 获取到biob文件流，进行渲染到页面预览
            });
          });
        } else {
          this.showFileType = 2;
        }
      } else {
        this.showFileType = "";
      }
    },
    checkdetail() {
      let params = {
        token: this.$store.state.token,
        id: this.$route.query.id,
      };
      spDetail(params).then((res) => {
        console.log("审批详情", res);
        if (this.type == "1") {
          this.$message.success("初审成功");
        }
      });
    },
    checkrz() {
      let params = {
        token: this.$store.state.token,
        id: this.$route.query.id,
      };
      spRizhi(params).then((res) => {
        console.log("审批日志", res);
        if (this.type == "1") {
          this.$message.success("初审成功");
        }
      });
    },
    tuihui() {
      let type = 0;
      if (this.nowType == 1) {
        type = 4;
      } else if (this.nowType == 2) {
        type = 5;
      } else if (this.nowType == 3) {
        type = 6;
      }
      let params = {
        token: this.$store.state.token,
        sid: this.$route.query.id,
        status: "13",
        content: this.content,
        type: type,
      };
      if (this.nowType == 3) {
        params.status = 16;
      }
      this.disabled = true;
      addExam(params)
        .then((res) => {
          console.log("退回审批", res);
          if (this.nowType == 7) {
            this.$message.success("标准终止，标准信息留档");
          } else {
            this.$message.success("退回到用户");
          }
        })
        .catch((err) => {
          this.disabled = true;
        });
    },
    goback() {
      this.$router.go(-1);
    },
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.getInfo();
    },
    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          // console.log("detial", res);
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          this.tableData = res.data.files.filter(
            (item) =>
              item.type == 32
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    download(row) {
      console.log(row, '打印元素')
      window.open(row.url)
    },
    submitStatus(name,url) {
      let params = {
        sid: this.id,
        url: url,
        token: this.token,
        name: name,
        type: 32
      }
      otherFiles(params)
        .then((res) => {
          console.log('打印返回', res)
          this.getInfo()
        })
        .catch((err) => {
          this.disabled = true;
        });
    }

  },
};
</script>
<style scoped>
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #ffffff;
}

/deep/.el-textarea__inner {
  border: none;
  min-height: 100px !important;
}

/deep/.el-textarea {
  height: 100px;
}

/deep/.el-timeline {
  padding-left: 10px;
  height: 75vh;
  overflow: hidden;
  overflow-y: scroll;
}

/deep/.el-timeline-item__content {
  padding-right: 10px;
}

.el-timeline::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped lang="scss">
.pitable {
  .upload_btn {
    float: right;
    margin: 20px 0 20px 0;
  }
}
</style>
